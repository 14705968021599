import { FC } from 'react';
import IconProps from './IconProps';

const CloseIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
    >
      <g filter="url(#filter0_d)">
        <path
          d="M19.0711 19.0711C15.1658 22.9763 8.83418 22.9763 4.92893 19.0711C1.02369 15.1658 1.02369 8.83418 4.92893 4.92893C8.83418 1.02369 15.1658 1.02369 19.0711 4.92893C22.9763 8.83418 22.9763 15.1658 19.0711 19.0711Z"
          fill="black"
        />
      </g>
      <path
        d="M15.3 9.52548C15.5278 9.29767 15.5278 8.92832 15.3 8.70052C15.0722 8.47271 14.7029 8.47271 14.4751 8.70052L15.3 9.52548ZM11.5877 11.5879C11.3599 11.8157 11.3599 12.185 11.5877 12.4128C11.8155 12.6406 12.1849 12.6406 12.4127 12.4128L11.5877 11.5879ZM12.4127 12.4128C12.6405 12.185 12.6405 11.8157 12.4127 11.5879C12.1849 11.3601 11.8155 11.3601 11.5877 11.5879L12.4127 12.4128ZM8.7004 14.4752C8.47259 14.703 8.47259 15.0724 8.7004 15.3002C8.9282 15.528 9.29755 15.528 9.52535 15.3002L8.7004 14.4752ZM12.4123 11.5875C12.1845 11.3597 11.8151 11.3597 11.5873 11.5875C11.3595 11.8153 11.3595 12.1846 11.5873 12.4124L12.4123 11.5875ZM14.4747 15.2998C14.7025 15.5276 15.0718 15.5276 15.2996 15.2998C15.5274 15.072 15.5274 14.7026 15.2996 14.4748L14.4747 15.2998ZM11.5873 12.4124C11.8151 12.6402 12.1845 12.6402 12.4123 12.4124C12.6401 12.1846 12.6401 11.8153 12.4123 11.5875L11.5873 12.4124ZM9.52494 8.70014C9.29713 8.47233 8.92779 8.47233 8.69998 8.70014C8.47218 8.92794 8.47218 9.29729 8.69998 9.52509L9.52494 8.70014ZM14.4751 8.70052L11.5877 11.5879L12.4127 12.4128L15.3 9.52548L14.4751 8.70052ZM11.5877 11.5879L8.7004 14.4752L9.52535 15.3002L12.4127 12.4128L11.5877 11.5879ZM11.5873 12.4124L14.4747 15.2998L15.2996 14.4748L12.4123 11.5875L11.5873 12.4124ZM12.4123 11.5875L9.52494 8.70014L8.69998 9.52509L11.5873 12.4124L12.4123 11.5875Z"
        fill="white"
      />
      <defs>
        <filter id="filter0_d" x="0.5" y="0.5" width="25" height="25" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="1.25" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.768627 0 0 0 0 0.768627 0 0 0 0 0.768627 0 0 0 0.18 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default CloseIcon;
